
      ;
      $app_version: 'v1.0.0_5809938';
      $app_name: 'official-pc';
      
@import "../../../../styles/utils.scss";

.container {
    width: 100%;
    margin: 0 auto;
    padding-top: 45px;
    justify-content: center;
    max-width: 1140px;

    .titleContainer {
        display: flex;
        justify-content: center;
        margin-bottom: 45px;
        img {
            width: 427px;
            height: 126px;
        }
    }
    .itemContainer {
        display: flex;
        align-items: flex-end;
        margin-bottom: 30px;
      }
      
      .item {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;

        img {
            width: 120px;
            display: inline-block;
        }
        .desc {
            font-size: 24px;
            color: #373A36;
            display: inline-block;
            margin-top: 14px;
        }
      }
      
}