
      ;
      $app_version: 'v1.0.0_5809938';
      $app_name: 'official-pc';
      
.help {
    padding-left: 360px;
    padding-right: 360px;
    margin-top: 80px;
    min-height: 1018px;
    padding-bottom: 60px;
    .mainSection {
        .title {
            text-align: center;
            margin-bottom: 10px;
        }
        p {
            margin-top: 20px;
        }
        h2 {
            border-bottom: none;
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 16px;
            margin-top: 16px;
        }
        h3 {
            font-weight: 400;
            font-size: 18px;
            margin-top: 12px;
            margin-bottom: 12px;
        }
        .bold {
            font-weight: bold;
        }
        .underLine {
            text-decoration: underline;
        }
        .textUnderline {
            text-decoration: underline;
            color: #333;
        }
        .indent {
            text-indent: 2em;
        }
        a {
            color: #4dacec;
            font: 14px;
        }
    }
}