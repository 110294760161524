
      ;
      $app_version: 'v1.0.0_5809938';
      $app_name: 'official-pc';
      
@import "../../styles/utils.scss";

.box {
  width: 100%;
  background: $body-bg;
  border-top: #D8D8D8 1px solid;
}

.friendLink {
  .groups {

    .groupTitle {
      font-weight: bold;
      color: #333;
    }

    .groupLink {
      line-height: 2;
      color: #666;
    }
  }
}

.copyRights {
  width: 100%;
  background: #3D3A39;
  border-top: 1px solid #979797;
  .copyRightsImage {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    .imgItem{
      margin-right: 30px;
      width: 117px;
      height: 48px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .address {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #C8C8C8;
    margin-bottom: 12px;
  }
  .copyRightLink {
    color: #C8C8C8;
  }
}

.box {
  padding-top: 55px;
}

.helpCode {
  width: 230px;
  height: 230px;
  margin-top: 60px;
}

.friendLink {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: flex-start;

  .logo {
    width: 152px;
    height: 54px;
    margin-top: 56px;
    flex: 0 0 auto;
  }

  .groups {
    position: relative;
    margin-left: 245px;
    display: flex;
    justify-content: space-between;

    .group {
      margin-right: 60px;

      &:last-child {
        margin-right: 0;
      }
    }

    .groupItem {
      white-space: nowrap;
    }

    .paragraph {
      white-space: initial;
    }

    .groupTitle {
      font-size: 14px;
      line-height: 2;
      display: block;
    }

    .groupLink {
      font-size: 14px;
      display: block;
    }

    a:hover {
      cursor: pointer;
    }
  }

  .kefu {
    width: 140px;
    height: 44px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 93px;
    margin-top: 10px;
    background-color: #F9E72C;
    &:hover {
      cursor: pointer;
    }
    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
}

.copyRights {
  margin-top: 49px;
  padding: 20px 0;
  text-align: center;
  font-size: 12px;

  .copyRightLink {
    color: #C8C8C8;
    line-height: 2;
    display: inline-block;

    &+.copyRightLink {
      margin-left: 0.5em;
    }

    &:nth-child(2) {
      margin-left: 3em;
    }
  }
}

.govLinks {
  font-size: 12px;

  .copyRightLink {
    &+.copyRightLink {
      margin-left: 1em;

      &::before {
        content: '|';
        display: inline-block;
        margin-right: 1em;
      }
    }
  }
}