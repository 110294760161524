
      ;
      $app_version: 'v1.0.0_5809938';
      $app_name: 'official-pc';
      
@import "../../styles/utils.scss";



.menu {
  a {
    display: inline-block;
    color: $heading;
  }

  .menuTitleWhite {
    color: #FFFFFF;
  }

  .menuTitleBlack {
    color: #000000;
  }
  

  .active {
    // text-shadow: 0px 0px 1px black;
    position: relative;
  }

  label {
    &::after {
      background: #F9E72C;
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 9;
    }
  }

  a {
    span {
      position: relative;

      &::after {
        background: #111;
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 9;
      }
    }
  }
}

.indicator {
  display: none;
}

.localer {
  .locale {
    color: #999999;
    cursor: pointer;

    &.active {
      color: $heading;
    }

    &+.locale {
      margin-left: 8px;

      &::before {
        content: '/';
        display: inline-block;
        color: $heading;
        margin-right: 8px;
      }
    }
  }
}


  .box {
    border-bottom: 1px solid #F5F5F4;
  }
  .helpCode {
    width: 230px;
    height: 230px;
    margin-top: 60px;
  }
  .special {
    background: linear-gradient(270deg, #F8D900 0%, #FFF544 100%);
  }
  .specialAbout {
    background-color: rgba(0, 0, 0, 0.35);
  }

  .menuIcon,
  .closeIcon {
    display: none;
  }

  .AppHeader {
    position: fixed;
    height: 84px;
    margin: 0 auto;
    width: 100%;
    min-width: 1200px;
    z-index: 99;
    top: 0;
    display: flex;
  }

  .header {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    flex: 0 0 auto;
    width: 152px;
    display: inline-block;
  }

  .menus {
    height: 42px;
    display: flex;
    align-items: center;
  }

  .menu {
    height: 100%;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    a:hover {
      cursor: pointer;
    }

    &:last-child {
      &:hover {
        .subMenus {
          left: auto;
          right: 0;
        }
      }
    }

    &:hover {
      .menuTitle {
        font-size: 16px;
        &:after {
          width: 100%;
          height: 3px;
          bottom: -10px;
        }
      }
      .subMenus {
        display: block;
      }
    }

    .menuBox {
      position: relative;
    }

    .activeIndicator {
      color: transparent;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }

    label {
      cursor: pointer;
    }

    label,
    a {

      &:hover,
      &.active {
        span {
          &::after {
            width: 100%;
            height: 3px;
            bottom: -10px;
          }
        }
      }
    }
  }

  .indicator {
    &:checked {
      &~.subMenus {
        display: block;
      }

      &+label {
        &::after {
          width: 24px;
          height: 3px;
          bottom: -10px;
        }
      }
    }
  }

  .title {
    &.inactive {
      &:hover,
      &.active {
        span {
          &::after {
            width: 0;
            height: 0;
          }
        }

        &+label {
          &::after {
            width: 0;
            height: 0;
          }
        }
      }
    }
  }

  .subMenus {
    position: absolute;
    z-index: 8;
    background-color: #fff;
    padding: 4px 0;
    top: 30px;
    left: 0;
    display: none;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);

    .subMenuItem {
      padding: 0 12px;

      .name {
        display: block;
        line-height: 2.25;
        white-space: nowrap;
      }

      .subMenu {
        padding-left: 20px;
      }
    }

    .subMenu {
      white-space: nowrap;
      color: #666;

      &.alone {
        padding: 0 12px;
        color: #333;
      }

      a {
        line-height: 2.25;
        display: inline-block;
        width: 100%;

        &:hover {
          text-shadow: 0px 0px 1px black;
        }

        &::after {
          display: none;
        }
      }
    }
  }
  .localer {
    position: absolute;
    top: 24px;
    right: 0;
    font-size: 14px;
    line-height: 20px;
  }



