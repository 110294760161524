$scale: 100;
$body-width: 7.5rem;
$design-output: 750;

$theme: #FFF700;
$body-bg: #FFFFFF;

$heading: #222222;
$body: #666666;

$pc-max-width: 100%;

$middle-padding: 20px;

$pc-min-width: 1200px;

@function ossImg($relativeImg) {
  @return 'https://www.atrenew.com/resources'+$relativeImg+'?_v=#{$app_version}';
}
