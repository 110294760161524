
      ;
      $app_version: 'v1.0.0_5809938';
      $app_name: 'official-pc';
      
@import "../../../styles/utils.scss";

.action {
  position: absolute;
  top: 65%;
  right: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background: linear-gradient(180deg, #3D3A39 0%, #000000 100%);
  border-radius: 25px;
  user-select: none;
  z-index: 999;

  .text {
    color: #ffff;
    margin-right: 5px;
  }
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .6);
  height: 100vh;
  z-index: 999;

  &:hover {
    cursor: pointer;
  }

  .videoBox {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;

    video {
      width: 800px;
      height: 400px;
    }

    .close {
      position: absolute;
      bottom: -100px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid #fff;
      cursor: pointer;
    }
  }

  .modalBox {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;

    .modalContent {
      background-color: #ffffff;
      height: 400px;
      width: 300px;
      border-radius: 10px;

      .title {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #EEEEEE;
      }

      img {
        height: 100px;
        width: 100px;
        margin-top: 80px;
      }

      .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        width: 100%;
        border-top: 1px solid #EEEEEE;
        padding: 12px 0;
        justify-content: center;
      }
    }

    .close {
      position: absolute;
      // bottom: -250px;
      top: -30px;
      right: -40px;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid #fff;
      cursor: pointer;
    }
  }
}

.Banner {
  width: 100%;
  text-align: center;
}

.wrapper {
  width: $pc-max-width;
  margin: 0 auto;
  position: relative;

  .shadowBanner {
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;

    &:hover {
      cursor: pointer;
    }
  }

  .download {
    position: absolute;
    bottom: 28%;
    left: 18%;
    z-index: 11;
    display: flex;
    flex-direction: row;
    align-items: center;

    .button {
      &:hover {
        cursor: pointer;
      }

      padding: 16px 40px;
      border-radius: 93px;
      font-size: 24;
      background-color: #F9E72C;
      color: #111111;
      margin-right: 20px;
      font-weight: bold;
      display: flex;
      align-items: center;

      img {
        height: 18px;
        margin-left: 8px;
      }

      .special {
        width: 20px;
        height: 20px;
        margin-left: 8px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

  }
}

.slideItem {
  position: relative;

  .banner {
    width: 100%;
  }

}

.pointer {
  &:hover {
    cursor: pointer;
  }
}

.helpCode {
  height: 230px;
  width: 230px;
  margin-top: 20px;
}

.desc {
  color: #373A36;
  font-size: 18px;
  margin-top: 28px;
  text-align: center;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 999;
}

.videoModal {
  & :global {
    .ant-modal-content {
      padding: 0;
    }
  }
}