
      ;
      $app_version: 'v1.0.0_5809938';
      $app_name: 'official-pc';
      
.background {
    background-image: url('../../assets/gongyi/gongyi-bg.png');
    height: 691px;
    width: 100%;
    background-size: cover;
    .title {
        width: 100%;
        margin-top: 90px;
        margin-left: 18%;
        display: inline-block;
        img {
            width: 343px;
            height: 114px;
        }
    }
    .content {
        width: 1200px;
        background-color: #fff;
        padding: 40px;
        margin: 0 auto;
        margin-top: 48px;
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.07);
        border: 1px solid #F5F5F5;
        border-radius: 12px;
        .item {
            display: flex;
            flex-direction: row;
            .left {
                display: inline-block;
                width: 260px;
                height: 260px;
                margin-right: 30px;
            }
            .right {
                display: inline-block;
                width: 260px;
                height: 260px;
                margin-left: 30px
            }
            .desc {
                .descTitle {
                    color: #373A36;
                    font-size: 32px;
                    margin-bottom: 20px;
                    font-weight: bold;
                }
                .descText {
                    font-size: 24px;
                    color: #373A36;
                }
            }
        }
        .line {
            background-color: #EEEEEE;
            height: 1px;
            margin: 30px 0;
        }
    }
    
}
.bottom {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    margin-top: 240px;
    margin-bottom: 80px;
    .bottomContainer {
        display: flex;
        flex-direction: row;
        .bottomItem{
            display: flex;
            flex-direction: row;
            width: 585px;
            background-color: #fff;
            box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.07);
            padding: 40px;
            border-radius: 12px;
            border: 1px solid #F5F5F5;
            img{
                height: 180px;
                width: 180px;
                margin-right: 20px;
            }
            .rightContent {
                .title {
                    color: #373A36;
                    font-weight: bold;
                    font-size: 24px;
                    margin-bottom: 20px;
                }
                .desc {
                    color: #373A36;
                    font-size: 20px;
                }
            }
            &:last-child {
                margin-left: 30px;
            }
        }
    }
}