
      ;
      $app_version: 'v1.0.0_5809938';
      $app_name: 'official-pc';
      
@import "../../styles/utils.scss";
.Home {
  width: 100%;
  --swiper-dot-active-color: #F9E72C;
  --swiper-dot-default-color: #E5E5E5;
}
