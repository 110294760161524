
      ;
      $app_version: 'v1.0.0_5809938';
      $app_name: 'official-pc';
      
@import "../../../../styles/utils.scss";

.background {
    margin-top: 70px;
    height: 837px;
    background: url('../../assets/moreWays/bg.png');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
        display: flex;
        margin-top: 310px;
        flex-direction: column;
        align-items: center;
        margin-bottom: 65px;
        .titleImg {
            width: 380px;
            height: 132px;
            margin-bottom: 14px;
        }
        .text {
            color: #FFFFFF;
            font-size: 24px;
        }
    }
    .block {
        display: flex;
        align-items: center;
        flex-direction: row;
        .blockItem {
            padding: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: #FFFFFF;
            border-radius: 24px;
            .blockImg {
                height: 120px;
                width: 120px;
            }
            .desc {
                color: #373A36;
                font-size: 32px;
                margin-left: 20px;
            }
        }
        
        .blockMiddleItem {
            padding: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #FFFFFF;
            border-radius: 24px;
            margin-left: 30px;
            margin-right: 30px;
            .blockImg {
                height: 120px;
                width: 120px;
            }
            .desc {
                color: #373A36;
                font-size: 32px;
                margin-left: 20px;
            }
        }
        
    }
}
