
      ;
      $app_version: 'v1.0.0_5809938';
      $app_name: 'official-pc';
      
.help {
    padding-left: 360px;
    padding-right: 360px;
    margin-top: 80px;
    min-height: 1018px;
    .mainSection {
        h2 {
            border-bottom: none;
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 16px;
            margin-top: 16px;
        }
        h3 {
            font-weight: 400;
            font-size: 18px;
            margin-top: 12px;
            margin-bottom: 12px;
        }
        .bold {
            font-weight: bold;
        }
        .underLine {
            text-decoration: underline;
        }
        .textUnderline {
            text-decoration: underline;
            color: #333;
        }
        .indent {
            text-indent: 2em;
        }
        table {
            width: 100%;
            margin: 10px 0;
            margin-bottom: 80px;
            border-collapse: collapse;
            th {
                border: 1px solid #ccc;
                padding: 10px 5px;
                text-align: center;
            }
            td {
                vertical-align: middle;
                text-align: center;
                border: 1px solid #ccc;
                padding: 10px 5px;
            }
            a {
                color: #4dacec;
                font: 14px;
            }
        }
    }
}