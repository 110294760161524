
      ;
      $app_version: 'v1.0.0_5809938';
      $app_name: 'official-pc';
      
.NotFound {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
