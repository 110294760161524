@use "sass:math";

@import "./variable.scss";

@mixin language-zh {
  html[lang=zh] {
    @content;
  }
}

@mixin language-en {
  html[lang=en] {
    @content;
  }
}

@function strip-units($number) {
  @return math.div($number, ($number * 0 + 1));
}

@mixin fs-rem($min-width, $max-width) {
  @if $max-width <=750 {
    @media screen and (min-width: $min-width * 1px) and (max-width: ($max-width + 1) * 1px) {
      font-size: math.div($max-width, math.div($design-output, $scale)) * 1px;
      font-size: math.div(100, math.div($design-output, $scale)) * 1vw;
    }
  }

  @if $max-width>750 {
    @media screen and (min-width: $min-width * 1px) {
      font-size: math.div($min-width, math.div($design-output, $scale)) * 1px;
      font-size: math.div(100, math.div($design-output, $scale)) * 1vw;
    }
  }
}

@function PX2REM($px){
  @return math.div(strip-units($px), $scale) * 1rem;
}



@function css-function( $function, $values... ) {
	@return
		$function
		+ unquote( '(' )
		+ $values
		+ unquote( ')' )
	;
}

@function css-min( $values... ) {
	@return css-function( min, $values );
}

@function css-max( $values... ) {
	@return css-function( max, $values );
}

@function css-clamp( $values... ) {
	@return css-function( clamp, $values );
}

@mixin line($lines: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lines;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.bottomLine {
  position: relative;
  padding-bottom: 8px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    background-color: var(--theme, #F9E72C);
  }

  &:before {
    bottom: 6px;
    height: 2px;
  }

  &:after {
    bottom: 0;
    height: 4px;
    border-radius: 0 0 5px 5px;
  }
}

$mobile-horizontal-padding: PX2REM(40px);

@mixin content-wrapper() {
  min-width: $pc-min-width;
}