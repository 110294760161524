
      ;
      $app_version: 'v1.0.0_5809938';
      $app_name: 'official-pc';
      
@import "../../styles/utils.scss";

.bannerSwiper {
  position: relative;

  :global {
    .swiper-container-horizontal {
      position: relative;
    }

    .swiper-container-horizontal>.swiper-pagination-bullets {
      width: 72px;
      position: absolute;
      z-index: 66;
      bottom: 30px;
      left: unset;
      right: calc(css-max(100vw, $pc-min-width) * .2);
      // transform: translateX(-50%);
      // border-radius: 8px;
      // line-height: 16px;

      >.swiper-pagination-bullet {
        background: rgba($color: #999, $alpha: .7);
        width: 16px;
        height: 16px;
      }

      >.swiper-pagination-bullet-active {
        background: #fff;
      }
    }
  }
}