
      ;
      $app_version: 'v1.0.0_5809938';
      $app_name: 'official-pc';
      
@import "../../styles/utils.scss";

.container {
    width: 100%;
    .background {
        width: 100%;
        height: 612px;
        background-size:cover;
        background-repeat:no-repeat;  
        background-image: url('./aboutus/about-us-banner.png');
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;
        .block {
            display: flex;
            flex-direction: column;
            text-align: left;
            width: 1200px;
            .titleone {
                width: 120px;
                margin-bottom: 40px;
            }
            .timeline {
                margin-bottom: 100px;
                width: 1160px;
            }
            .titletwo {
                width: 360px;
                height: 70px;
                margin-bottom: 34px;
               
            }
            .partner {
                width: 1200px;
            }
        }
    }
}
