
      ;
      $app_version: 'v1.0.0_5809938';
      $app_name: 'official-pc';
      
@import "../../../../styles/utils.scss";

.background {
    background-color: #ffffff;
    height: 720px;
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 44px;
        .titleImg {
            width: 380px;
            height: 132px;
            margin-bottom: 14px;
        }
    }
    .block {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: 30px;
        .blockItem {
            padding: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: #FFFFFF;
            box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.07);
            border-radius: 24px;
            border: 1px solid #F5F5F5;
            .blockImg {
                height: 120px;
                width: 120px;
            }
            .desc {
                color: #373A36;
                font-size: 42px;
                margin-left: 20px;
            }
        }
        
        .blockMiddleItem {
            padding: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: #FFFFFF;
            box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.07);
            border-radius: 24px;
            border: 1px solid #F5F5F5;
            margin-left: 30px;
            margin-right: 30px;
            .blockImg {
                height: 120px;
                width: 120px;
            }
            .desc {
                color: #373A36;
                font-size: 42px;
                margin-left: 20px;
            }
        }
        
    }
}