
      ;
      $app_version: 'v1.0.0_5809938';
      $app_name: 'official-pc';
      
@import "../../../../styles/utils.scss";

.download {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba($color: #FFFFFF, $alpha: 0.9);
    border-top: 1px solid;
    border-color: rgba(231, 231, 231, 0.5);
    height: 140px;
    z-index: 66;
    width: 100%;
    display: flex;
    align-items: center;

    .container {
        min-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .desc {
            img {
                height: 86px;
            }
        }
        .codeContainer {
            .code {
                display: flex;
                align-items: center;
                flex-direction: column;
                padding: 6px;
                border: 1px solid #F8D900;
                border-radius: 6px;
                background-color: rgba($color: #FFFFFF, $alpha: 0.9);
                img {
                    width: 74px;
                    height: 74px;
                }
            }
            p {
                font-size: 12px;
                margin-top: 4px;
            }
        }
    }
    .close {
        position: absolute;
        right: 60px;
        top: 50%;
        transform: translateY(-50%);
        img {
            width: 32px;
            height: 32px;
        }
    }
}