
      ;
      $app_version: 'v1.0.0_5809938';
      $app_name: 'official-pc';
      
@import "../../../../styles/utils.scss";

.parnter {
    width: 100%;
    .top {
        background-image: url('../../assets/parnter/backgroundShadow.png');
        background-size: cover;
        background-position: center center;
        height: 328px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .parnterTextContainer {
            display: flex;
            justify-content: center;
            margin-top: 90px;
            margin-bottom: 50px;
            img {
                width: 168px;
            }
        }
        .iconRow {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .iconItem {
                img {
                    width: 210px;
                }
            }
        }
    }
    .iconRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .iconItem {
            img {
                width: 210px;
            }
        }
    }
}