
      ;
      $app_version: 'v1.0.0_5809938';
      $app_name: 'official-pc';
      
@import "./styles/utils.scss";

* {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: "AlibabaPuHuiTi", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.bottom-bar-visible {
    padding-bottom: 140px;
  }
}

a {
  color: $heading;

  &:hover {
    text-decoration: none;
    color: currentColor;
    outline: none;
  }

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:visited {
    outline: none;
  }
}

button {
  border: none;
  outline: none;

  &:active,
  &:focus {
    outline: none;
  }
}

p {
  margin: 0;
}

p {
  line-height: 2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  overflow-x: auto;
}

body {
  font-size: 16px;
  @include content-wrapper();
}

.block-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.hidden-large {
  display: none !important;
}

.ant-modal {
  .ant-modal-close {
    top: 26px;
    right: 24px;
  }

  .ant-modal-footer {
    &:empty {
      display: none;
    }
  }
}